import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'datatables.net-dt';
import {decode} from 'html-entities';
import { faAdd, faTrash, faSave, faPen, faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import Textbox from '../Textbox';
import Texteditor from '../Texteditor';
import Authbutton from '../Authbutton';
import Selectmenu from '../Selectmenu';
import {UserContext} from '../../contexts/UserContext';

var catVal='';
const Book = ({userdata, modalShow, modalClose})=>{
  var nameVal=''; 
  const navigate = useNavigate();
  const usercxt = useContext(UserContext);
   const[data, setData] = useState({
    'book':[],
    'booktype':[],
    'bookcategory':[],
    'searchkey':'',
    'searchindex':-1,
    'txteditor':false,
    'loaded':false,
    'visiblity':'1',
    'i':0,
    'anc':{firIcon:faAdd, adding:true},
    'ant':{firIcon:faAdd, adding:true}
  });

  useEffect( () => {
    if(data.i===0 && userdata.baseurl) {
      modalShow();
            fetch(userdata.baseurl+'author/book/fetchonlanding/'+userdata.id)
    .then((res)=>res.json())
            .then(response => {
              if(response==='login'){
              navigate('/');
             }else{
              setData(pstate =>{ 
                return {...pstate,
                  book:response.p,
                  bookcategory:response.c,
                  booktype:response.t,
                  loaded:true,
                  i:1,
                }});
             }
            }); 
      modalClose();
    }
  }, [data.i]);

  useEffect( () => {
    if(data.loaded) {
      setTimeout(()=>
    new DataTable('#dataTable', {
      responsive: true,
      pageLength:10,
      searching: true, 
      retrieve:true,
  }), 2000); 
}
  }, [data.loaded]);

  const editData = (id) => {
    var findindex = data.book.findIndex((value)=>value.id===id);
  setData(pstate =>{ return {...pstate, searchkey:id, searchindex:findindex, visiblity:data.book.find((value)=>value.id===id).vis}});
  }

  const deleteData = (e) => {
    if(window.confirm('Do you want to delete this book and its chapters?')) {
      modalShow();
      fetch(userdata.baseurl+'author/book/deleteAbook/'+e+'/'+userdata.id)
      .then((res)=>res.json())
              .then(response => {
                modalClose();
                  setData(pstate =>{ return {...pstate, book:response}});
              });
    }
  }

  const handleCategoryIcon = (e) => {
    e.preventDefault();
    catVal='';
    if(data.anc.adding) {
      setData(pstate =>{ return {...pstate, anc:{firIcon:faSave, adding:false}}});
    }else{
      if(nameVal !== ''){
        modalShow();
        fetch(userdata.baseurl+'author/book/addcategory/'+nameVal.toLowerCase()+'/'+userdata.id)
        .then((res)=>res.json())
                .then(response => {
                  modalClose();
                  if(response==='login'){
                   navigate('/');
                  }else{
                    setData(pstate =>{ return {...pstate, bookcategory:response}});
                  }
                });
                nameVal='';
      }
      setData(pstate =>{ return {...pstate, anc:{firIcon:faAdd, adding:true}}});
    }
  }

      const handleTypeIcon = (e) => {
        e.preventDefault();
        if(data.ant.adding){
          setData(pstate =>{ return {...pstate, ant:{firIcon:faSave, adding:false}}});
        }else{
          if(nameVal !== ''){
            modalShow();
            fetch(userdata.baseurl+'author/book/addtype/'+nameVal.toLowerCase()+'/'+userdata.id)
            .then((res)=>res.json())
                    .then(response => {
                      modalClose();
                      if(response==='login'){
                       navigate('/');
                      }else{
                        setData(pstate =>{ return {...pstate, booktype:response}});
                      }
                    });
                    nameVal='';
          }
          setData(pstate =>{ return {...pstate, ant:{firIcon:faAdd, adding:true}}});
        }
      }

      const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.currentTarget.classList.add('was-validated');
        if (event.currentTarget.checkValidity()) {
          event.currentTarget.classList.remove('was-validated');
          let data = new FormData(event.currentTarget);
          event.currentTarget.reset();
          modalShow();
        fetch(userdata.baseurl+'author/book/add', {method:'POST', body: data})
		.then((res)=>res.json())
            .then(response => { 
              modalClose();
              catVal='';
              if(response==='login'){
               navigate('/');
              }else{
              setData(pstate =>{ return {...pstate, book:response,searchindex:-1, searchkey:''}});
              }
                });
      }
        }

       const handleReset = (event) => {
        event.preventDefault();
        catVal='';
        var currentTarget = document.querySelector('#form');
          currentTarget.classList.remove('was-validated');
          currentTarget.reset();
          setData(pstate =>{ return {...pstate, searchindex:-1, searchkey:''}});
        }

        const categoryName = (id) => {
            for(var i=0; i<data.bookcategory.length; i++) {
                if(data.bookcategory[i].value===id){
                   return data.bookcategory[i].label;
                }
            }
        }

  return (
    data.book.length > 0 &&
    <>
    <div className="row">
    <div className="col-md-4">
    <div className="card shadow-lg">
    <div className="card-body px-3 py-5">
      <h1 className="fs-4 card-title fw-bold mb-4">Add new publication</h1>
							<form id='form' onSubmit={handleSubmit} noValidate={true}>
            <input name="uid" value={userdata.id} type="hidden" />
            <input name="id" value={data.searchindex < 0  ? '' : data.book[data.searchindex].id} type="hidden" />
            <input name="cover0" value={data.searchindex < 0  ? '' : data.book[data.searchindex].frontcover} type="hidden" />
            <input name="cover1" value={data.searchindex < 0  ? '' : data.book[data.searchindex].backcover} type="hidden" />
            
            <div className="mb-3">
	<label className="mb-2 text-muted" htmlFor="type">Type</label>
              <div className="input-group">
               {
                  data.ant.adding ?
		<Selectmenu 
					name='typ'
          style={{borderRadius: '0.375rem 0 0 0.375rem', width: '275px'}}
					options={data.booktype}
                    value={data.searchindex < 0  ? '' : data.book[data.searchindex].type}
                    setvalue={(e)=>null}
                              label='Type'
				/>
                 :
        <Textbox 
name='typ'
type='text'
label='Type'
setvalue={(e)=>nameVal=e}
value=''
style={{borderRadius: '0.375rem 0 0 0.375rem', width: '275px'}}
/>
                  }
              <button onClick={handleTypeIcon} className="mybtn input-group-text" >
              <FontAwesomeIcon icon={data.ant.firIcon} />
              </button>
</div>
</div>

              <div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="category">Category</label>
              <div className="input-group">
               {
                  data.anc.adding ?
									<Selectmenu 
					name='category'
          style={{borderRadius: '0.375rem 0 0 0.375rem', width: '275px'}}
					options={data.bookcategory}
          value={data.searchindex < 0  ? catVal : data.book[data.searchindex].category}
          setvalue={(e)=>null}
					label='Category'
				/>
                 :
        <Textbox 
name='category'
type='text'
label='Category'
setvalue={(e)=>nameVal=e}
value=''
style={{borderRadius: '0.375rem 0 0 0.375rem', width: '275px'}}
/>
                  }
              <button onClick={handleCategoryIcon} className="mybtn input-group-text">
              <FontAwesomeIcon icon={data.anc.firIcon} />
              </button>

</div>

									</div>

                                    <div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="title">Title</label>
        <Textbox 
name='title'
type='text'
label='Title'
style={{height:'auto'}}
value={data.searchindex < 0  ? '' : data.book[data.searchindex].title}
setvalue={(e)=>null}
/>
</div>

                  <div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="price">Price</label>
              <div className="input-group">
        <Textbox 
name='price'
type='text'
label='Price'
value={data.searchindex < 0  ? '' : data.book[data.searchindex].price}
setvalue={(e)=>null}
style={{borderRadius: '0.375rem 0 0 0.375rem', width:'150px'}}
/>
<Selectmenu 
					name='cur'
          style={{borderRadius: '0 0.375rem 0.375rem 0', width:'160px'}}
					options={usercxt.currency}
          setvalue={(e)=>null}
          value={data.searchindex < 0  ? '' : data.book[data.searchindex].cur}
					label='Currency'
				/>

</div>
									</div>

                                    <div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="author">Author; <small className='text-danger'> Leave blank if you are the author</small></label>
        <Textbox 
name='author'
type='text'
label='Author'
style={{height:'auto'}}
value={data.searchindex < 0  ? '' : data.book[data.searchindex].author}
setvalue={(e)=>null}
/>
</div>

                  <div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="file0">Front cover Image</label>
        <input 
name='file0'
type='file'
accept='image/*'
className='form-control'
/>
</div>

<div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="file0">Back cover Image</label>
        <input 
name='file1'
type='file'
accept='image/*'
className='form-control'
/>
</div>

<div className="mb-3">
                                    <label className="mb-2 text-muted" htmlFor="vis">Visiblity</label>
                                <div style={{borderRadius: '0'}} className="form-check-inline input-group-text">
                          <label>
                                    <input onChange={(e)=>setData(pstate =>{ return {...pstate, visiblity:e.target.value}})} checked={data.visiblity==='0'} className="form-check-input me-2" value="0" name="vis" type="radio" />
                                    Private
                                  </label>
                                  <label className="inline ms-4">
                                    <input onChange={(e)=>setData(pstate =>{ return {...pstate, visiblity:e.target.value}})} checked={data.visiblity ==='1'} className="form-check-input me-2" value="1" name="vis" type="radio" />
                                    Public
                                  </label>                                  
                                </div> 
                                    </div>

<div className="mb-3">
<Texteditor value={data.searchindex < 0  ? '' : data.book[data.searchindex].preview} name='preview' label='Preview' />
</div>

								<div className="d-flex align-items-center">
                <button onClick={handleReset} className="btn btn-link text-dark">Reset form</button>
									<Authbutton name='Submit' />
								</div>
            </form>  
            </div>
          </div>
          </div>
    <div className="col-md-8">
          <div className="table-responsive">
          <table id="dataTable" className="table table-striped table-bordered">
              <thead>
                <tr>
                                        <th>Front cover</th>
                                        <th>Back cover</th>
                                        <th>Category</th>
                                        <th>Title</th>
                                        <th>Author</th>
                                        <th>Price</th>
                                        <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
              data.book.length > 0 &&
              data.book.map(opt=>{
            return <tr key={opt.id}>
              <td className={opt.id === data.searchkey ? 'text-bg-primary' : ''} > <img style={{width:'50px', height:'30px'}} src={userdata.baseurl+'backend/_authors/book/'+opt.frontcover} className="img-thumbnail" alt="None" /> </td>
              <td className={opt.id === data.searchkey ? 'text-bg-primary' : ''} > <img style={{width:'50px', height:'30px'}} src={userdata.baseurl+'backend/_authors/book/'+opt.backcover} className="img-thumbnail" alt="None" /> </td>
              <td className={opt.id === data.searchkey ? 'text-bg-primary' : ''} > {categoryName(opt.category)} </td>
              <td className={opt.id === data.searchkey ? 'text-bg-primary' : ''} > {decode(opt.title)} </td>
              <td className={opt.id === data.searchkey ? 'text-bg-primary' : ''} > {opt.author} </td>
              <td className={opt.id === data.searchkey ? 'text-bg-primary' : ''} >
                {
                  usercxt.getcurrency(opt.cur)+decode(opt.price)
                }
                </td>
                                            <td style={{width: '120px'}} className={opt.id === data.searchkey ? 'text-bg-primary' : ''} >
                                            <button onClick={()=>navigate('/chapter/'+opt.id)} className="btnlite" title="Chapters">
                                            <FontAwesomeIcon icon={faSquareArrowUpRight} />
                                                    </button>
                                            <button onClick={()=>editData(opt.id)} className="btnlite" title="Edit">
                                            <FontAwesomeIcon icon={faPen} />
                                                    </button>
                                                    <button onClick={()=>deleteData(opt.id)} className="btnlite" title="Delete">
                                                    <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                            </td>
            </tr>
        }) 
      }
                </tbody>
                </table>
                </div>
    </div>
  </div>
    </>
  );
}
export default Book;