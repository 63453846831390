import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home'
import AuthApp from './widgets/auth/App';
import DashApp from './widgets/dashboard/App';
import Logout from './widgets/dashboard/Logout';
import UserContextProvider from './contexts/UserContext';
import Detail from './Detail';

function App() {
  return (
    <UserContextProvider>
     <BrowserRouter>
     <Routes>
      <Route exact path='/' element={<Home />} /> 
      <Route exact path='/:category/:title' element={<Detail />} />
      <Route path='/forgotpassword' element={<AuthApp />} /> 
      <Route path='/login' element={<AuthApp />} /> 
      <Route path='/register' element={<AuthApp />} /> 
      <Route path='/dashboard' element={<DashApp />} /> 
      <Route path='/paper' element={<DashApp />} /> 
      <Route path='/publication' element={<DashApp />} />
      <Route path='/question/:paperId' element={<DashApp />} />
      <Route path='/chapter/:bookId' element={<DashApp />} />
      <Route path='/paragraph/:bookId/:chapterId' element={<DashApp />} />
      <Route path='/signout' element={<Logout />} /> 
      <Route/>
      </Routes>
    </BrowserRouter>
    </UserContextProvider>
  );
}

export default App;
