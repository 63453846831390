import React, { useState } from 'react';
import Homeloda  from './widgets/Homeloda';
import Paging from './widgets/Paging';
import Header from './widgets/Header';

function Home() {
  const[data, setData] = useState({
    'papercnt':0,
    'bookcnt':0,
    'paperindex':0,
    'bookindex':0,
  });

  return (
    <div className='h-100 text-center text-bg-dark'>
      <Header />
      <main>
      <div style={{backgroundColor:'#502020'}} className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center">
      <div className="col-md-5 p-lg-5 mx-auto my-5">
        <h1 className="display-6 fw-normal">MOLINA EDUCATION</h1>
        <p className="lead fw-normal my-4">
          Quiz, Exam papers, Multi-play, Single-play, Virtual-play, Chats, Live Forum, Books, School Projects, 
          Lession Notes, Competitions, Tournaments, Leaderboard, Winning, Earning Money.
        </p>
        <button className="btn btn-outline-secondary" href="#"><img alt='' height="20" width="20" src={process.env.PUBLIC_URL + '/images/google-play-store-icon.svg'} /> Google Play</button>
        <button className="btn btn-outline-secondary ms-3" href="#"><img alt='' height="20" width="20" src={process.env.PUBLIC_URL + '/images/google-play-store-icon.svg'} /> Android APK</button>
      </div>
      <div className="product-device shadow-sm d-none d-md-block">
        <h3 className="mt-5">
            HELLO I AM A MOBILE PHONE FROM THE ANDROID VERSION
        </h3>
      </div>
      <div className="product-device product-device-2 shadow-sm d-none d-md-block" >
        <h3 className="mt-5 text-bg-primary">HELLO I AM A MOBILE PHONE FROM THE ANDROID VERSION</h3>
      </div>
    </div>

    <div className="d-md-flex flex-md-equal w-100 my-md-3 ps-md-3">
        <div className="text-bg-dark me-md-3 pt-3 px-3 px-md-5 text-center overflow-hidden" >
          <div className="py-3">
            <h2 className="display-5">Papers</h2>
            <p className="lead">New exam papers this week.</p>
          </div>
          <div className="text-bg-success shadow-sm p-3" style={{borderRadius: '21px 21px 0 0'}}>
          <Homeloda noimg='paper' seg={'author/paper/listbylimit/'+data.paperindex} setcount={(e)=>setData(pstate =>{ 
                    return {...pstate,
                      papercnt:data.papercnt > 0 ? data.papercnt:e}})} />
          {
                data.papercnt > 12 &&
                <Paging onclick={(e)=>setData(pstate =>{ return {...pstate, paperindex:e}})} ttl={data.papercnt} index={data.paperindex} />
              }
            </div>
        </div>
        <div className="text-bg-success me-md-3 pt-3 px-3 px-md-5 text-center overflow-hidden">
          <div className="p-3">
            <h2 className="display-5">Publications</h2>
            <p className="lead">This Week's Publications.</p>
          </div>
          <div className="bg-dark shadow-sm p-3" style={{borderRadius: '21px 21px 0 0'}}>
              <Homeloda noimg='book' seg={'author/book/listbylimit/'+data.bookindex} setcount={(e)=>setData(pstate =>{ 
                    return {...pstate,
                      bookcnt:data.bookcnt > 0 ? data.bookcnt:e}})} />
              {
                data.bookcnt > 12 &&
                <Paging />
              }
            </div>
        </div>
      </div>

      </main>

      <footer className="mt-auto text-white-50">
        <p>
          Copyright &COPY; Deparment of Computer Science<br />
          Nile University of Nigeria.
        </p>
      </footer>
    </div>
  );
}

export default Home;
