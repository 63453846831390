import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faDashboard, faQuestion, faBook, faSection, faParagraph } from '@fortawesome/free-solid-svg-icons';

export default function Nav() {
  const loc=useLocation();

  return (
    <>
    <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-body-tertiary sidebar collapse" >
          <div className="position-sticky pt-3 sidebar-sticky">
            <ul className="nav flex-column">
              <li className="nav-item">
										<Link state="/dashboard" className={'nav-link'+(loc.pathname==='/dashboard' ? ' active' : '')} to="/dashboard">
                    <FontAwesomeIcon icon={faDashboard} className="me-1" />Dashboard</Link>
              </li>
              <li className="nav-item">
                <Link className={'nav-link'+(loc.pathname==='/paper' ? ' active' : '')} state="/paper" to="/paper">
                  
                <FontAwesomeIcon icon={faPaperclip} className="me-1" />
                  Papers
                </Link>
              </li>
              <li className="nav-item">
                <Link className={'nav-link'+(loc.pathname.indexOf('question') > -1 ? ' active' : '')} state="/question">
                  
                <FontAwesomeIcon icon={faQuestion} className="me-1" />
                  Questions
                </Link>
              </li>
              
              <li className="nav-item">
                <Link className={'nav-link'+(loc.pathname==='/publication' ? ' active' : '')} state="/publication" to="/publication">
                  
                <FontAwesomeIcon icon={faBook} className="me-1" />
                Publications
                </Link>
              </li>

              <li className="nav-item">
                <Link className={'nav-link'+(loc.pathname.indexOf('chapter') > -1 ? ' active' : '')} state="/chapter">
                  
                <FontAwesomeIcon icon={faSection} className="me-1" />
                  Chapters
                </Link>
              </li>

              <li className="nav-item">
                <Link className={'nav-link'+(loc.pathname.indexOf('paragraph') > -1 ? ' active' : '')} state="/paragraph">
                  
                <FontAwesomeIcon icon={faParagraph} className="me-1" />
                Paragraphs
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" >
                  <span
                    data-feather="shopping-cart"
                    className="align-text-bottom"
                  ></span>
                  Products
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" >
                  <span data-feather="users" className="align-text-bottom"></span>
                  Customers
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" >
                  <span
                    data-feather="bar-chart-2"
                    className="align-text-bottom"
                  ></span>
                  Reports
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" >
                  <span data-feather="layers" className="align-text-bottom"></span>
                  Integrations
                </Link>
              </li>
            </ul>

            <h6
              className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary text-uppercase"
            >
              <span>Saved reports</span>
              <Link className="link-secondary" aria-label="Add a new report">
                <span
                  data-feather="plus-circle"
                  className="align-text-bottom"
                ></span>
              </Link>
            </h6>
            <ul className="nav flex-column mb-2">
              <li className="nav-item">
                <Link className="nav-link" >
                  <span
                    data-feather="file-text"
                    className="align-text-bottom"
                  ></span>
                  Current month
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" >
                  <span
                    data-feather="file-text"
                    className="align-text-bottom"
                  ></span>
                  Last quarter
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" >
                  <span
                    data-feather="file-text"
                    className="align-text-bottom"
                  ></span>
                  Social engagement
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" >
                  <span
                    data-feather="file-text"
                    className="align-text-bottom"
                  ></span>
                  Year-end sale
                </Link>
              </li>
            </ul>
          </div>
        </nav>
    </>
  );
}
