import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'datatables.net-dt';
import {decode} from 'html-entities';
import parser from 'html-react-parser';
import { faAdd, faDeleteLeft, faPen } from '@fortawesome/free-solid-svg-icons';
import Authbutton from '../Authbutton';
import Selectmenu from '../Selectmenu';
import Paragraphrow from './Paragraphrow';

var afr=[1];
const Paragraph = ({userdata, param, chapid, modalShow, modalClose, modalContent})=>{
  const navigate = useNavigate();
   const[data, setData] = useState({
    'chapter':[],
    'paragraph':[],
    'searchkey':'',
    'searchindex':-1,
    'loaded':false,
    'import':false,
    'i':0,
  });

  //const[images, setImages] = useState([]);
  // images.push('myimg.jpg');
  // setImages([...images]);

  useEffect( ()=> {
    if(data.i===0 && userdata.baseurl){
      modalShow();
            fetch(userdata.baseurl+'author/chapter/fetchonlanding/'+userdata.id+'/'+param+'/'+chapid)
    .then((res)=>res.json())
            .then(response => {
              if(response==='login'){
              navigate('/');
             }else{
              setData(pstate =>{ 
                return {...pstate,
                  chapter:response.b.length===0 ? null : response.b,
                  paragraph:response.c,
                  loaded:true,
                  i:1
                }});
             }
            });
      modalClose();
    }
  }, [data.i]);

  useEffect( () =>{
    if(data.loaded){
      setTimeout(()=>
    new DataTable('#dataTable', {
      responsive: true,
      pageLength:10,
      searching: true, 
      retrieve:true,
  }), 2000); 
}
  }, [data.loaded]);

  const editData = (id) => {
    var findindex = data.paragraph.findIndex((value)=>value.id===id);
  setData(pstate =>{ return {...pstate, searchkey:id, searchindex:findindex}});
  }

  const deleteData = (e) => {
    if(window.confirm('Do you want to delete this?')){
      modalShow();
      var formdata= new FormData();
      formdata.append('id', e);
        formdata.append('uid', userdata.id);
        formdata.append('param', chapid);
        formdata.append('table', 'paragraphs');
      fetch(userdata.baseurl+'author/chapter/delete', {method:'POST', body:formdata})
      .then((res)=>res.json())
              .then(response => {
                modalClose();
                  setData(pstate =>{ return {...pstate, paragraph:response}});
              });
    }
  }

      const handleSubmit = (event) => {
        const seg = data.import===true ? 'import' : 'add';
        event.preventDefault();
        event.stopPropagation();
        event.currentTarget.classList.add('was-validated');
        if (event.currentTarget.checkValidity()) {
          event.currentTarget.classList.remove('was-validated');
          let data = new FormData(event.currentTarget);
          event.currentTarget.reset();
          modalShow();
        fetch(userdata.baseurl+'author/chapter/'+seg, {method:'POST', body: data})
		.then((res)=>res.json())
            .then(response => { 
              if(response==='login'){
                modalClose();
               navigate('/');
              }else if(response.indexOf('Error') > -1){
                modalContent(response);
              }else{
                modalClose();
              setData(pstate =>{ return {...pstate, paragraph:response, searchindex:-1, searchkey:''}});
              }
                });
      }
}

 const onaddformrow= (e)=>{
     e.preventDefault(); 
     afr.push(afr.length+1);
     setData(pstate =>{ return {...pstate, searchindex:-1, searchkey:'',import:false}});
 }

 const loadagain = (e)=>{
  afr=[1];
  setData(pstate =>{ return {...pstate, i:0,paragraph:[],loaded:false,searchkey:'',searchindex:-1,import:false,}});
  navigate('/paragraph/'+chapid+'/'+e);
 }

 const handleReset = (event) => {
  event.preventDefault();
  var currentTarget = document.querySelector('#form');
    currentTarget.classList.remove('was-validated');
    currentTarget.reset();
    setData(pstate =>{ return {...pstate, searchindex:-1, searchkey:''}});
  }

  const uploadMultiple = (event) => {
    event.preventDefault();
        event.stopPropagation();
        event.currentTarget.classList.add('was-validated');
        if (event.currentTarget.checkValidity()) {
          event.currentTarget.classList.remove('was-validated');
          let data = new FormData(event.currentTarget);
          event.currentTarget.reset();
          modalShow();
        fetch(userdata.baseurl+'author/chapter/uploadmultiple', {method:'POST', body: data})
		.then((res)=>res.json())
            .then(response => { 
              if(response==='login'){
              modalClose();
               navigate('/');
              }else{
                modalContent(response);
              }
    })
  }
}

const download = (e) => {
  e.preventDefault();
  if(window.confirm('Do you want to download this?')){
    window.open(userdata.baseurl+'author/question/download/paragraphs/xlsx', '_blank');
  }
}

  return (
    data.chapter.length > 0 &&
    <>
    <div className="row">
    <div className="col-lg-4">
    <div className="card shadow-lg">
    <div className="card-body py-5 px-3">
      <h1 className="fs-4 card-title fw-bold mb-4">Add new paragraph</h1>
							<form id='form' onSubmit={handleSubmit} encType='multipart/form-data' noValidate={true}>
              <input name="uid" value={userdata.id} type="hidden" />
              <input name="table" value='paragraphs' type="hidden" />
            <input name="id" value={data.searchindex < 0  ? '' : data.paragraph[data.searchindex].id} type="hidden" />
            <input name="avatar" value={data.searchindex < 0  ? '' : data.paragraph[data.searchindex].avatar} type="hidden" />
                <div className='mb-3'>
              <div className="input-group">
                <Selectmenu 
					name='param'
          style={{borderRadius: '0.375rem 0 0 0.375rem', width: '275px'}}
					options={data.chapter}
          value={chapid}
          setvalue={(e)=>loadagain(e)}
					label='Chapter'
				/>
              <button onClick={()=>navigate('/chapter/'+param)} className="mybtn input-group-text">
              <FontAwesomeIcon icon={faAdd} />
                </button>

</div></div>

<div className='mb-3 d-flex align-items-center'>
                    <button onClick={(e)=>{e.preventDefault(); afr=[1]; setData(pstate =>{ return {...pstate, searchkey:'',import:!data.import}});}} className='btn btn-link text-muted me-1'>{data.import ? 'No excel import' :'Import excel file'}</button>
                    {
                    data.import ? 
                    <button onClick={(e)=>download(e)} className="btn btn-link text-info" >Download Format</button>
                    :
                    <button onClick={(e)=>onaddformrow(e)} className="btn btn-link text-info" >Add Form Row</button>
}
                    </div>
{
                    data.import ?
                    <div className='mb-3'>
<input type="file" name="import" accept=".csv, .xls, .xlsx" className="form-control" />
</div>
:
  afr.map(opt=>{
     return  <Paragraphrow id={opt} key={opt}
                      di={data.searchindex < 0  ? 'bb' : data.paragraph[data.searchindex].display_image}
                      title={data.searchindex < 0  ? '' : data.paragraph[data.searchindex].title}
                      txt={data.searchindex < 0  ? '' : data.paragraph[data.searchindex].txt}
              />
    })
}

								<div className="d-flex align-items-center">
                <button onClick={handleReset} className="btn btn-link text-dark">Reset form</button>
									<Authbutton name='Submit' />
								</div>
            </form>  
            </div>
          </div>

          <div className="card shadow-lg my-5">
    <div className="card-body px-3 py-5">
      <h3 className="fs-4 card-title fw-bold mb-4">Upload images for the imported excel data</h3>
							<form onSubmit={uploadMultiple} encType='multipart/form-data' noValidate={true}>
              <input name="uid" value={userdata.id} type="hidden" /> 
              <input type='file' accept='image/*' className='form-control mb-2' name='files[]' /> 
              <input type='file' accept='image/*' className='form-control mb-2' name='files[]' /> 
              <input type='file' accept='image/*' className='form-control mb-2' name='files[]' /> 
              <input type='file' accept='image/*' className='form-control mb-2' name='files[]' /> 
              <input type='file' accept='image/*' className='form-control mb-2' name='files[]' />  
                                <button type="submit" className="btn btn-info pull-right my-4">Upload</button>
                            </form></div></div>
          </div>

          <div className="col-lg-8">
          <div className="card shadow-lg">
    <div className="card-body p-5">
    <div className="table-responsive">
          <table id="dataTable" style={{width:'645px'}} className="table table-striped table-bordered">
              <thead>
              <tr>
                                        <th>Title</th>
                                        <th>Body text</th>
                                        <th style={{textAlign:'center'}}>Action</th>
                                    </tr>
              </thead>
              <tbody>
                {
              data.paragraph.length > 0 &&
              data.paragraph.map(opt=>{
            return <tr key={opt.id}>
              <td style={{width:'30%'}} className={opt.id === data.searchkey ? 'text-bg-primary' : ''} > <div dangerouslySetInnerHTML={{__html:decode(opt.title)}} ></div> </td>
              <td style={{width:'50%'}} className={opt.id === data.searchkey ? 'text-bg-primary' : ''} > {parser(decode(opt.txt))} </td>
                                            <td style={{width:'20%', textAlign:'center'}} className={opt.id === data.searchkey ? 'text-bg-primary' : ''} >
                                             <button onClick={()=>editData(opt.id)} className="btnlite" title="Edit">
                                            <FontAwesomeIcon icon={faPen} />
                                                    </button>
                                                    <button onClick={()=>deleteData(opt.id)} className="btnlite" title="Delete">
                                                    <FontAwesomeIcon icon={faDeleteLeft} />
                                                    </button>
                                            </td>
            </tr>
        }) 
      }
                </tbody>
                </table>
                </div>
                </div>
                </div>
    </div>
  </div>
    </>
  );
}
export default Paragraph;