
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCode, faTextHeight, faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import {decode} from 'html-entities';
import Textarea from "./Textarea";
import { Editor } from '@tinymce/tinymce-react';
import Modal from 'react-bootstrap/Modal';

var globalval='';
const MyModal = (props) => {
  return (
  <Modal id='modaleditor' size='xl' fullscreen='xl-down' centered={true} backdrop='static' show={props.show} onHide={props.onhide} >
<Modal.Dialog>
<Modal.Header closeButton={true}></Modal.Header>
  <Modal.Body>
  {
                props.iseditor ?
      <Editorarea />
      :
        <Textarea style={{height:'500px', width:'1000px'}} setvalue={(e)=>globalval=e} value={globalval} />
}
  </Modal.Body>
  <Modal.Footer>
  <button onClick={(e)=>{ e.preventDefault(); props.onhide(); }} className="btn btn-secondary text-end">Done</button>
  </Modal.Footer>
</Modal.Dialog>
</Modal>
  );
}

const Editorarea = (props) => {
	const [data, setData] = useState({'initval':globalval, 'newval':''});

	return(
<Editor
      licenseKey="gpl"
         initialValue={globalval}
         textareaName={props.name}
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
         onInit={(evt, editor)=>{
          setData(pstate =>{ return {...pstate, initval:data.newval, init:false}})
         }}
         init={{
           height: 500,
           toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
         }}
         value={data.newval}
         onEditorChange={
          (content)=>{
            globalval=content;
          setData(pstate =>{ return {...pstate, newval:content, init:false}})
          }
        }
       />
        );
      }

const Texteditor = (props) => {
  const [data, setData] = useState({'iseditor':false, 'showmodal':false, 'init':true});
  const modalClose = ()=>setData(pstate =>{ return {...pstate, showmodal:false, init:false}});
  globalval = data.init ? decode(props.value) :  globalval;

	return(
        <>
	   <label className="mb-2 text-muted" htmlFor={props.name}>{props.label}</label>
                  {
                data.iseditor ?
      <Editorarea name={props.name}/>
      :
        <Textarea style={{height:'auto'}} setvalue={(e)=>globalval=e} name={props.name} label={props.label} value={globalval} />
}
<button style={{padding:'0.1rem 0.25rem'}} onClick={(e)=>{e.preventDefault(); setData(pstate =>{ return {...pstate, iseditor:!data.iseditor, init:false}}) }} className="btnlite">
              <FontAwesomeIcon icon={data.iseditor ? faTextHeight : faCode} />
                </button>
                <button style={{padding:'0.1rem 0.5rem'}} onClick={(e)=>{e.preventDefault(); setData(pstate =>{ return {...pstate, showmodal:true, init:false}}) }} className="btnlite ms-2">
              <FontAwesomeIcon icon={faSquareArrowUpRight} />
                </button>
                <MyModal iseditor={data.iseditor} show={data.showmodal} onhide={modalClose} />
		</>
	)
}
export default Texteditor;