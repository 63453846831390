import Textbox from '../Textbox';
import Authbutton from '../Authbutton';
import { Link, Outlet } from 'react-router-dom';

const Forgotpassword = (props)=> {
  return (
	<>
    <div className="card">
						<div className="card-body p-5">
							<h1 className="fs-4 card-title fw-bold mb-4">Forgot Password</h1>
							<form onSubmit={props.submit} noValidate={true}>
								<div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="email">Email Address</label>
									<Textbox 
					name='email'
					type='email'
					label='Email Address'
					value=''
					style={{height:'auto'}}
					setvalue={(e)=>null}
				/>
								</div>

								<div className="d-flex align-items-center">
                                <Authbutton name='Send link' />
								</div>
							</form>
						</div>
						<div className="card-footer py-3 border-0">
							<div className="text-center">
								Remember your password? <Link className="text-dark" to="/login">Login</Link>
							</div>
						</div>
					</div>
					<Outlet />
					</>
  );
}

export default Forgotpassword;
