
import { useState } from "react";
import {decode} from 'html-entities';
const Textarea = (props) => {
	const [input, setInput] = useState(props.value);
	const setVal = (e)=>{
		  props.setvalue(e);
		  setInput(e);
	  }
	var dataset= input === '' ? decode(props.value) : input;

	return(
        <>
        <textarea style={props.style} onChange={(e)=>setVal(e.target.value)} className='form-control' value={dataset} name={ props.name } ></textarea>
		<div className='invalid-feedback'>
        { props.label } is invalid
		</div>
		</>
	)

}

export default Textarea;