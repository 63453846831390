import React from 'react';

const Authbutton = (props) => {
	return(
        <button type="submit" className="btn btn-primary ms-auto">
										{ props.name }
									</button>
	)
}

export default Authbutton;