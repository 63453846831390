import { useLocation, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import Header from './Header';
import Nav from './Nav';
import Dashboard from './Dashboard';
import Paper from './Paper';
import Book from './Book';
import Question from './Question';
import Chapter from './Chapter';
import Paragraph from './Paragraph';
import MyModal from '../MyModal';
import {UserContext} from '../../contexts/UserContext';

function App() {
	const usercxt = useContext(UserContext);
	const navigate = useNavigate();
	 useEffect(()=>{
	if(usercxt.user===null){
		navigate('/');
	}
}); 

const loc=useLocation();
const [moda, setModa] = useState({head:'', body:'', show:false});
const modalClose = ()=>setModa(pstate =>{ return {...pstate, show:false}});
const modalShow = ()=>setModa(pstate =>{ return {...pstate, show:true}});
const modalContent = (m)=>setModa({head:'<div className="me-5">Message</div>', body:m, show:true});
var Pathr = Dashboard, param='', chapid='';
	if(loc.pathname==='/paper'){
		Pathr=Paper
	}else if(loc.pathname==='/publication'){
		Pathr=Book
	}else if(loc.pathname.indexOf('question') > -1){
		Pathr=Question;
		param=loc.pathname.replace('/question/', '');
	}else if(loc.pathname.indexOf('chapter') > -1){
		Pathr=Chapter;
		param=loc.pathname.replace('/chapter/', '');
	}else if(loc.pathname.indexOf('paragraph') > -1){
		Pathr=Paragraph;
		var p=loc.pathname.replace('/paragraph/', ''), c=p.split('/');
		param=c[0];
		chapid=c[1];

	}

  return (
	<>
	<MyModal head={moda.head} body={moda.body} show={moda.show} onhide={modalClose} />
	<Header />
	<div className="container-fluid">
      <div className="row">
	  <Nav />
	  <main className="col-md-9 ms-sm-auto col-lg-10 mt-4">
      <Pathr userdata={usercxt.user ?? {}} param={param} chapid={chapid} modalShow={modalShow} modalContent={(m)=>modalContent(m)} modalClose={modalClose} />
	  </main>
	  </div>
	  </div>
	</>
  );
}

export default App;
