import { useNavigate } from 'react-router-dom';
import React, { useEffect, useContext } from 'react';
import {UserContext} from '../../contexts/UserContext';

export default function Logout() {
    const usercxt = useContext(UserContext);
	const navigate = useNavigate();
	useEffect(()=>{
	if(usercxt.user!==null){
        usercxt.changeUserStatus(null);
		navigate('/');
    }
	});

  return (
    <></>
  );
}
