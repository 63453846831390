import { useState } from "react";
import Select from "react-select";
import {decode} from 'html-entities';

const Selectmenu = (props) => {  
	const [input, setInput] = useState(props.value);
  const setVal = (e)=>{
		props.setvalue(e);
		setInput(e);
	}
  var dataset= input === '' ? props.value : input;

	return(
        <div>
          {
            props.name==='cur' ?
            <select value={dataset} onChange={(e)=>setVal(e.target.value) } id={ props.name } name={ props.name } style={props.style} className="form-select" required={true}  >
      <option value="" disabled={true} >Choose {props.label}...</option>
      {
        props.options.length > 0 &&
        props.options.map(opt=>{
            return <option value={opt.value} key={opt.value} >{decode(opt.label)}</option>
        }) 
      }
    </select>
      :
			<Select 
      options={props.options} 
      value={props.options.filter(e=>e.value===dataset)}
      placeholder={'Choose '+props.label+'...'}
      onChange={(e)=> setVal(e.value) } 
      id={ props.name } name={ props.name } 
      styles={{
        control: (defaultStyles, state) => { return {
          ...defaultStyles, ...props.style};
        },
      }}
      required={true}  />
    }
    <div className='invalid-feedback'>
        { props.label } is invalid
		</div>
		</div>
	)

}

export default Selectmenu;