import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'datatables.net-dt';
import {decode} from 'html-entities';
import { faAdd, faDeleteLeft, faPen, faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import Authbutton from '../Authbutton';
import Selectmenu from '../Selectmenu';
import Chapterow from './Chapterow';

var afr=[1];
const Chapter = ({userdata, param, modalShow, modalClose, modalContent})=>{
  const navigate = useNavigate();
   const[data, setData] = useState({
    'book':[],
    'chapter':[],
    'searchkey':'',
    'searchindex':-1,
    'loaded':false,
    'import':false,
    'i':0,
  });

  //const[images, setImages] = useState([]);
  // images.push('myimg.jpg');
  // setImages([...images]);

  useEffect( ()=> {
    if(data.i===0 && userdata.baseurl){
      modalShow();
            fetch(userdata.baseurl+'author/chapter/fetchonlanding/'+userdata.id+'/'+param+'/0')
    .then((res)=>res.json())
            .then(response => {
              if(response==='login'){
              navigate('/');
             }else{
              setData(pstate =>{ 
                return {...pstate,
                  book:response.b.length===0 ? null : response.b,
                  chapter:response.c,
                  loaded:true,
                  i:1
                }});
             }
            });
      modalClose();
    }
  }, [data.i]);

  useEffect( () =>{
    if(data.loaded){
      setTimeout(()=>
    new DataTable('#dataTable', {
      responsive: true,
      pageLength:10,
      searching: true, 
      retrieve:true,
  }), 2000); 
}
  }, [data.loaded]);

  const editData = (id) => {
    var findindex = data.chapter.findIndex((value)=>value.id===id);
  setData(pstate =>{ return {...pstate, searchkey:id, searchindex:findindex}});
  }

  const deleteData = (e) => {
    if(window.confirm('Do you want to delete this chapter and its paragraphs?')){
      modalShow();
      var formdata= new FormData();
      formdata.append('id', e);
        formdata.append('uid', userdata.id);
        formdata.append('param', param);
        formdata.append('table', 'chapters');
      fetch(userdata.baseurl+'author/chapter/delete', {method:'POST', body:formdata})
      .then((res)=>res.json())
              .then(response => {
                modalClose();
                  setData(pstate =>{ return {...pstate, chapter:response}});
              });
    }
  }

      const handleSubmit = (event) => {
        const seg = data.import===true ? 'import' : 'add';
        event.preventDefault();
        event.stopPropagation();
        event.currentTarget.classList.add('was-validated');
        if (event.currentTarget.checkValidity()) {
          event.currentTarget.classList.remove('was-validated');
          let data = new FormData(event.currentTarget);
          event.currentTarget.reset();
          modalShow();
        fetch(userdata.baseurl+'author/chapter/'+seg, {method:'POST', body: data})
		.then((res)=>res.json())
            .then(response => { 
              if(response==='login'){
                modalClose();
               navigate('/');
              }else if(response.indexOf('Error') > -1){
                modalContent(response);
              }else{
                modalClose();
              setData(pstate =>{ return {...pstate, chapter:response, searchindex:-1, searchkey:''}});
              }
                });
      }
}

 const onaddformrow= (e)=>{
     e.preventDefault(); 
     afr.push(afr.length+1);
     setData(pstate =>{ return {...pstate, searchindex:-1, searchkey:'',import:false}});
 }

 const loadagain = (e)=>{
  afr=[1];
  setData(pstate =>{ return {...pstate, i:0,chapter:[],loaded:false,searchkey:'',searchindex:-1,import:false,}});
  navigate('/chapter/'+e);
 }

 const handleReset = (event) => {
  event.preventDefault();
  var currentTarget = document.querySelector('#form');
    currentTarget.classList.remove('was-validated');
    currentTarget.reset();
    setData(pstate =>{ return {...pstate, searchindex:-1, searchkey:''}});
  }

  const bookName = (id) => {
    for(var i=0; i<data.book.length; i++) {
        if(data.book[i].value===id){
           return data.book[i].label;
        }
    }
}

const download = (e) => {
  e.preventDefault();
  if(window.confirm('Do you want to download this?')){
    window.open(userdata.baseurl+'author/question/download/chapters/xlsx', '_blank');
  }
}

  return (
    data.book.length > 0 &&
    <>
    <div className="row">
    <div className="col-lg-4">
    <div className="card shadow-lg">
    <div className="card-body px-3 py-5">
      <h1 className="fs-4 card-title fw-bold mb-4">Add new chapter</h1>
							<form id='form' onSubmit={handleSubmit} encType='multipart/form-data' noValidate={true}>
              <input name="uid" value={userdata.id} type="hidden" />
              <input name="table" value='chapters' type="hidden" />
            <input name="id" value={data.searchindex < 0  ? '' : data.chapter[data.searchindex].id} type="hidden" />
            
            <div className='mb-3'>
              <div className="input-group">
                <Selectmenu 
					name='param'
          style={{borderRadius: '0.375rem 0 0 0.375rem', width: '275px'}}
					options={data.book}
          value={param}
          setvalue={(e)=>loadagain(e)}
					label='Book'
				/>
              <button onClick={()=>navigate('/publication')} className="mybtn input-group-text">
              <FontAwesomeIcon icon={faAdd} />
                </button>

</div>
                    </div>
                    
                    <div className='mb-3 d-flex align-items-center'>
                    <button onClick={(e)=>{e.preventDefault(); afr=[1]; setData(pstate =>{ return {...pstate, searchkey:'',import:!data.import}});}} className='btn btn-link text-muted me-1'>{data.import ? 'No excel import' :'Import excel file'}</button>
                    {
                    data.import ? 
                    <button onClick={(e)=>download(e)} className="btn btn-link text-info" >Download Format</button>
                    :
                    <button onClick={(e)=>onaddformrow(e)} className="btn btn-link text-info" >Add Form Row</button>
}
                    </div>
{
                    data.import ?
                    <div className='mb-3'>
<input type="file" name="import" accept=".csv, .xls, .xlsx" className="form-control" />
</div>
:
  afr.map(opt=>{
     return  <Chapterow key={opt} title={data.searchindex < 0  ? '' : data.chapter[data.searchindex].title}
              />
    })
}

								<div className="d-flex align-items-center">
                <button onClick={handleReset} className="btn btn-link text-dark">Reset form</button>
									<Authbutton name='Submit' />
								</div>
            </form>  
            </div>
          </div>
          </div>

          <div className="col-lg-8">
          <div className="card shadow-lg">
    <div className="card-body p-5">
          <div className="table-responsive">
          <table id="dataTable" style={{width:'645px'}} className="table table-striped table-bordered">
              <thead>
              <tr>
                                        <th >Book</th>
                                        <th >Title</th>
                                        <th style={{textAlign:'center'}}>Action</th>
                                    </tr>
              </thead>
              <tbody>
                {
              data.chapter.length > 0 &&
              data.chapter.map(opt=>{
            return <tr key={opt.id}>
              <td style={{width:'30%'}} className={opt.id === data.searchkey ? 'text-bg-primary' : ''} > {bookName(opt.bookid)} </td>
              <td style={{width:'40%'}} className={opt.id === data.searchkey ? 'text-bg-primary' : ''} > {decode(opt.title)} </td>
                                            <td style={{width:'30%', textAlign:'center'}} className={opt.id === data.searchkey ? 'text-bg-primary' : ''} >
                                            <button onClick={()=>navigate('/paragraph/'+param+'/'+opt.id)} className="btnlite" title="Paragraph">
                                            <FontAwesomeIcon icon={faSquareArrowUpRight} />
                                                    </button>
                                            <button onClick={()=>editData(opt.id)} className="btnlite" title="Edit">
                                            <FontAwesomeIcon icon={faPen} />
                                                    </button>
                                                    <button onClick={()=>deleteData(opt.id)} className="btnlite" title="Delete">
                                                    <FontAwesomeIcon icon={faDeleteLeft} />
                                                    </button>
                                            </td>
            </tr>
        }) 
      }
                </tbody>
                </table>
                </div>
                </div>
                </div>
    </div>
  </div>
    </>
  );
}
export default Chapter;