import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'datatables.net-dt';
import {decode} from 'html-entities';
import { faAdd, faTrash, faCancel, faSave, faPen, faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import Textbox from '../Textbox';
import Texteditor from '../Texteditor';
import Authbutton from '../Authbutton';
import Selectmenu from '../Selectmenu';
import {UserContext} from '../../contexts/UserContext';

var catVal='', subVal='';
const Paper = ({userdata, modalShow, modalClose})=>{
  var nameVal=''; 
  const navigate = useNavigate();
  const usercxt = useContext(UserContext);
   const[data, setData] = useState({
    'paper':[],
    'papercategory':[],
    'papersubject':[],
    'searchkey':'',
    'searchindex':-1,
    'txteditor':false,
    'loaded':false,
    'i':0,
    'anc':{firIcon:faAdd, secIcon:faTrash,adding:true, saving:false},
    'ans':{firIcon:faAdd, secIcon:faTrash,adding:true, saving:false}
  });

  useEffect( () =>{
    if(data.i===0 && userdata.baseurl){
      modalShow();
            fetch(userdata.baseurl+'author/paper/fetchonlanding/'+userdata.id)
    .then((res)=>res.json())
            .then(response => {
              if(response==='login'){
              navigate('/');
             }else{
              setData(pstate =>{ 
                return {...pstate,
                  paper:response.p,
                  papercategory:response.c,
                  loaded:true,
                  i:1,
                }});
             }
            }); 
      modalClose();
    }
  }, [data.i]);

  useEffect( () =>{
    if(data.loaded){
      setTimeout(()=>
    new DataTable('#dataTable', {
      responsive: true,
      pageLength:10,
      searching: true, 
      retrieve:true,
  }), 2000); 
}
  }, [data.loaded]);

  const editData = (id) => {
    var findindex = data.paper.findIndex((value)=>value.id===id);
  populateSubject(data.paper.find((value)=>value.id===id).cat);
  setData(pstate =>{ return {...pstate, searchkey:id, searchindex:findindex}});
  }

  const deleteData = (e) => {
    if(window.confirm('Do you want to delete this?')){
      modalShow();
      fetch(userdata.baseurl+'author/paper/delete/'+userdata.id+'/'+e)
      .then((res)=>res.json())
              .then(response => {
                modalClose();
                  setData(pstate =>{ return {...pstate, paper:response}});
              });
    }
  }

  const handleCategoryFirIcon = (e) => {
    e.preventDefault();
    nameVal='';
    subVal='';
    if(data.anc.adding){
      setData(pstate =>{ return {...pstate, anc:{firIcon:faCancel, secIcon: faSave, adding:false, saving:true}}});
    }else{
      catVal='';
      setData(pstate =>{ return {...pstate, anc:{firIcon:faAdd, secIcon: faTrash, adding:true, saving:false}}});
    }
  }

  const handleCategorySecIcon = (e) => {
    e.preventDefault();
    if(data.anc.saving){
      if(nameVal !== ''){
        modalShow();
        var formdata= new FormData();
        formdata.append('uid', userdata.id);
        formdata.append('x', nameVal);
        formdata.append('y', catVal);
      fetch(userdata.baseurl+'author/paper/addCategory', {method:'POST', body:formdata})
      .then((res)=>res.json())
              .then(response => {
                modalClose();
                if(response==='login'){
                 navigate('/');
                }else{
                  setData(pstate =>{ return {...pstate, papercategory:response}});
                }
              });
            }
            setData(pstate =>{ return {...pstate, anc:{firIcon:faAdd, secIcon: faTrash, adding:true, saving:false}}});
      }else{
        if(catVal !== ''){
          if(window.confirm('Do you want to delete this?')){
        modalShow();
      fetch(userdata.baseurl+'author/paper/removeCategory/'+catVal+'/'+userdata.id)
      .then((res)=>res.json())
              .then(response => {
                modalClose();
                if(response==='login'){
                 navigate('/');
                }else{
                  setData(pstate =>{ return {...pstate, papercategory:response}});
                }
              });
            }
          }
        }
          catVal='';
          nameVal='';
          subVal='';
      }

      const handleSubjectFirIcon = (e) => {
        e.preventDefault();
        nameVal='';
        if(data.ans.adding){
          setData(pstate =>{ return {...pstate, ans:{firIcon:faCancel, secIcon: faSave, adding:false, saving:true}}});
        }else{
          subVal='';
          setData(pstate =>{ return {...pstate, ans:{firIcon:faAdd, secIcon: faTrash, adding:true, saving:false}}});
        }
      }
    
      const handleSubjectSecIcon = (e) => {
        e.preventDefault();
        var formdata= new FormData();
        if(data.ans.saving){
          if(nameVal !== ''){
            modalShow();
            formdata.append('uid', userdata.id);
            formdata.append('x', nameVal);
            formdata.append('y', catVal);
            formdata.append('z', subVal);
          fetch(userdata.baseurl+'author/paper/addSubject', {method:'POST', body:formdata})
          .then((res)=>res.json())
                  .then(response => {
                    modalClose();
                    if(response==='login'){
                     navigate('/');
                    }else{
                      setData(pstate =>{ return {...pstate, papersubject:response}});
                    }
                  });
                }
          setData(pstate =>{ return {...pstate, ans:{firIcon:faAdd, secIcon: faTrash, adding:true, saving:false}}});
          }else{
            if(subVal !== ''){
              if(window.confirm('Do you want to delete this?')){
            modalShow();
            formdata.append('uid', userdata.id);
            formdata.append('x', subVal);
            formdata.append('y', catVal);
          fetch(userdata.baseurl+'author/paper/removeSubject', {method:'POST', body:formdata})
          .then((res)=>res.json())
                  .then(response => {
                    modalClose();
                    if(response==='login'){
                     navigate('/');
                    }else{
                      setData(pstate =>{ return {...pstate, papersubject:response}});
                    }
                  });
                }
              }
            }
              subVal='';
              nameVal='';
          }

      const populateSubject = (e) => {
        catVal=e;
        modalShow();
    fetch(userdata.baseurl+'author/paper/populateThings/'+userdata.id+'/'+catVal)
    .then((res)=>res.json())
            .then(response => {
             modalClose();
              if(response==='login'){
               navigate('/');
              }else{
                setData(pstate =>{ return {...pstate, papersubject:response}});
              }
            });
      }

      const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.currentTarget.classList.add('was-validated');
        if (event.currentTarget.checkValidity()) {
          event.currentTarget.classList.remove('was-validated');
          let data = new FormData(event.currentTarget);
          event.currentTarget.reset();
          modalShow();
        fetch(userdata.baseurl+'author/paper/add', {method:'POST', body: data})
		.then((res)=>res.json())
            .then(response => { 
              modalClose();
              subVal='';
              catVal='';
              if(response==='login'){
               navigate('/');
              }else{
              setData(pstate =>{ return {...pstate, paper:response,searchindex:-1, searchkey:''}});
              }
                });
      }
        }

       const handleReset = (event) => {
        event.preventDefault();
        subVal='';
        catVal='';
        var currentTarget = document.querySelector('#form');
          currentTarget.classList.remove('was-validated');
          currentTarget.reset();
          setData(pstate =>{ return {...pstate, searchindex:-1, searchkey:''}});
        }

  return (
    data.paper.length > 0 &&
    <>
    <div className="row">
    <div className="col-md-4">
    <div className="card shadow-lg">
    <div className="card-body px-3 py-5">
      <h1 className="fs-4 card-title fw-bold mb-4">Add new paper</h1>
							<form id='form' onSubmit={handleSubmit} noValidate={true}>
            <input name="uid" value={userdata.id} type="hidden" />
            <input name="rid" value={data.searchindex < 0  ? '' : data.paper[data.searchindex].id} type="hidden" />
            <input name="cover0" value={data.searchindex < 0  ? '' : data.paper[data.searchindex].frontcover} type="hidden" />
            <input name="cover1" value={data.searchindex < 0  ? '' : data.paper[data.searchindex].backcover} type="hidden" />
              <div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="category">Category</label>
              <div className="input-group">
               {
                  data.anc.adding ?
									<Selectmenu 
					name='category'
          style={{borderRadius: '0.375rem 0 0 0.375rem', width: '230px'}}
					options={data.papercategory}
          value={data.searchindex < 0  ? catVal : data.paper[data.searchindex].cat}
          setvalue={(e)=>populateSubject(e)}
					label='Category'
				/>
                 :
        <Textbox 
name='category'
type='text'
label='Category'
value=''
setvalue={(e)=>nameVal=e}
style={{borderRadius: '0.375rem 0 0 0.375rem', width: '230px'}}
/>
                  }
              <button onClick={handleCategoryFirIcon} className="mybtn input-group-text" style={{borderRadius: '0'}}>
              <FontAwesomeIcon icon={data.anc.firIcon} />
              </button>
              <button onClick={handleCategorySecIcon} className="mybtn input-group-text">
              <FontAwesomeIcon icon={data.anc.secIcon} />
                </button>

</div>

									</div>

              
                  <div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="Subject">Subject</label>
              <div className="input-group">
               {
                  data.ans.adding ?
									<Selectmenu 
					name='subject'
          style={{borderRadius: '0.375rem 0 0 0.375rem', width: '230px'}}
					options={data.papersubject}
          value={data.searchindex < 0  ? subVal : data.paper[data.searchindex].subj}
          setvalue={(e)=>subVal=e}
					label='Subject'
				/>
                 :
        <Textbox 
name='subject'
type='text'
label='Subject'
value=''
setvalue={(e)=>nameVal=e}
style={{borderRadius: '0.375rem 0 0 0.375rem', width: '230px'}}
/>
                  }
              <button onClick={handleSubjectFirIcon} className="mybtn input-group-text" style={{borderRadius: '0'}}>
              <FontAwesomeIcon icon={data.ans.firIcon} />
              </button>
              <button onClick={handleSubjectSecIcon} className="mybtn input-group-text">
              <FontAwesomeIcon icon={data.ans.secIcon} />
                </button>

</div>

									</div>

                  <div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="price">Price</label>
              <div className="input-group">
        <Textbox 
name='price'
type='text'
label='Price'
value={data.searchindex < 0  ? '' : data.paper[data.searchindex].price}
setvalue={(e)=>null}
style={{borderRadius: '0.375rem 0 0 0.375rem', width:'150px'}}
/>
<Selectmenu 
					name='cur'
          style={{borderRadius: '0 0.375rem 0.375rem 0', width:'160px'}}
					options={usercxt.currency}
          setvalue={(e)=>null}
          value={data.searchindex < 0  ? '' : data.paper[data.searchindex].cur}
					label='Currency'
				/>

</div>
									</div>

                  <div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="file0">Front cover Image</label>
        <input 
name='file0'
type='file'
accept='image/*'
className='form-control'
/>
</div>

<div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="file0">Back cover Image</label>
        <input 
name='file1'
type='file'
accept='image/*'
className='form-control'
/>
</div>
<div className="mb-3">
<Texteditor value={data.searchindex < 0  ? '' : data.paper[data.searchindex].preview} name='preview' label='Preview' />
</div>

								<div className="d-flex align-items-center">
                <button onClick={handleReset} className="btn btn-link text-dark">Reset form</button>
									<Authbutton name='Submit' />
								</div>
            </form>  
            </div>
          </div>
          </div>
    <div className="col-md-8">
          <div className="table-responsive">
          <table id="dataTable" className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col">Front cover</th>
                                        <th scope="col">Back cover</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Subject</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {
              data.paper.length > 0 &&
              data.paper.map(opt=>{
            return <tr key={opt.id}>
              <td className={opt.id === data.searchkey ? 'text-bg-primary' : ''} > <img style={{width:'50px', height:'30px'}} src={userdata.baseurl+'backend/_authors/paper/'+opt.frontcover} className="img-thumbnail" alt="None" /> </td>
              <td className={opt.id === data.searchkey ? 'text-bg-primary' : ''} > <img style={{width:'50px', height:'30px'}} src={userdata.baseurl+'backend/_authors/paper/'+opt.backcover} className="img-thumbnail" alt="None" /> </td>
              <td className={opt.id === data.searchkey ? 'text-bg-primary' : ''} > {decode(opt.category)} </td>
              <td className={opt.id === data.searchkey ? 'text-bg-primary' : ''} > {decode(opt.subject)} </td>
              <td className={opt.id === data.searchkey ? 'text-bg-primary' : ''} >
                {
                  usercxt.getcurrency(opt.cur)+decode(opt.price)
                }
                </td>
                                            <td style={{width: '120px'}} className={opt.id === data.searchkey ? 'text-bg-primary' : ''} >
                                            <button onClick={()=>navigate('/question/'+opt.id)} className="btnlite" title="Questions">
                                            <FontAwesomeIcon icon={faSquareArrowUpRight} />
                                                    </button>
                                            <button onClick={()=>editData(opt.id)} className="btnlite" title="Edit">
                                            <FontAwesomeIcon icon={faPen} />
                                                    </button>
                                                    <button onClick={()=>deleteData(opt.id)} className="btnlite" title="Delete">
                                                    <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                            </td>
            </tr>
        }) 
      }
                </tbody>
                </table>
                </div>
    </div>
  </div>
    </>
  );
}
export default Paper;