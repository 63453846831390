import Textbox from '../Textbox';
import Authbutton from '../Authbutton';

const Reset = (props)=> {
  return (
	<div className="card">
						<div className="card-body p-5">
							<h1 className="fs-4 card-title fw-bold mb-4">Reset Password</h1>
							<form onSubmit={props.submit} noValidate={true}>
								<div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="password">New Password</label>
									<Textbox 
					name='password'
					type='password'
					label='Password'
					value=''
					style={{height:'auto'}}
					setvalue={(e)=>null}
				/>
								</div>

								<div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="password-confirm">Confirm Password</label>
									<Textbox 
					name='password'
					type='password'
					value=''
					style={{height:'auto'}}
					label='Please confirm your new password'
					setvalue={(e)=>null}
				/>
								</div>

								<div className="d-flex align-items-center">
									<div className="form-check">
										<input type="checkbox" name="logout_devices" id="logout" className="form-check-input" />
										<label htmlFor="logout" className="form-check-label">Logout all devices</label>
									</div>
                                    <Authbutton name='Reset Password' />
								</div>
							</form>
						</div>
					</div>
  );
}

export default Reset;
