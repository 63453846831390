import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useContext } from 'react';
import Login from './Login';
import Register from './Register';
import Forgotpassword from './Forgotpassword';
import Reset from './Reset';
import MyModal from '../MyModal';
import {UserContext} from '../../contexts/UserContext';

function App() {
	const loc=useLocation();
	const [moda, setModa] = useState({head:'', body:'', show:false});
	const userContext = useContext(UserContext);
	const navigate = useNavigate();
	const handleClose = ()=>setModa(pstate =>{ return {...pstate,show:false}});
	let Pathr = Login, pathname=loc.pathname=== '/' ? '/login' : loc.pathname;
	if(pathname==='/forgotpassword'){
		Pathr=Forgotpassword
	}else if(pathname==='/register'){
		Pathr=Register;
	} else if(pathname==='/reset'){
		Pathr=Reset;
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();
		event.currentTarget.classList.add('was-validated');
		if (event.currentTarget.checkValidity()) {
			let data =new FormData(event.currentTarget);
		setModa({head:'Please wait...', body:'We are processing your request.', show:true});
		fetch(userContext.baseurl+'appserve/auth'+pathname, {method:'POST', body: data})
		.then((res)=>res.json())
            .then(response => {
				if(response.id===-1){
					if(pathname==='/register') {
						setModa(pstate =>{ return {...pstate, head:'Registration Failed!', body:'Email address is invalid.'}});
					}
				} else if(response.id===0){
					if(pathname==='/login') {
						setModa(pstate =>{ return {...pstate, head:'Login Failed!', body:'Wrong username or password.'}});
					} else if(pathname==='/register') {
						setModa(pstate =>{ return {...pstate, head:'Registration Failed!', body:'Email address already exist.'}});
					}
				}else{
					if(pathname==='/register') {
						userContext.changeUserStatus({'id':response.id,'email':data.get('email'), 'firstname':data.get('firstname'), 'lastname':data.get('lastname'), 'sex':data.get('gender'), 'baseurl':userContext.baseurl})
					}else{
					userContext.changeUserStatus({...response, 'baseurl':userContext.baseurl});
					}
				    navigate('/dashboard');
				}
            });
		}
	  }

  return (
	<>
	<MyModal head={moda.head} body={moda.body} show={moda.show} onhide={handleClose} />
    <section className="h-100">
		<div className="container h-100">
			<div className="row justify-content-sm-center h-100">
				<div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
					<div className="text-center my-5">
					<a href='./'>	<img src={process.env.PUBLIC_URL + '/images/logo.svg'} alt="logo" width="100" /></a>
					</div>
                    <Pathr submit={handleSubmit} />
                    <div className="text-center mt-5 text-muted mb-5">
						Copyright &copy; 2017-2021 &mdash; Your Company 
					</div>

				</div>
			</div>
		</div>
	</section>
	</>
  );
}

export default App;
