
import React, { useState, useEffect, useContext } from 'react';
import {decode} from 'html-entities';
import Stack  from './Stack';
import Spinner from 'react-bootstrap/Spinner';
import {UserContext} from '../contexts/UserContext';

const Homeloda = (props) => {
    const[data, setData] = useState({
        'infodata':[],
        'loading':true,
      });

    const usercxt = useContext(UserContext);
    var lnk = usercxt.baseurl+props.seg;

    useEffect( ()=> {
                fetch(lnk)
        .then((res)=>res.json())
                .then(response => {
                  props.setcount(response.c);
                  setData(pstate =>{ 
                    return {...pstate,
                        infodata:response.d.length===0 ? [] : response.d, loading:false
                    }});
                });
      }, [lnk]);

	return(
    data.loading ?
        <div className='d-flex py-3 justify-content-center'>
<Spinner animation="grow" variant="success" />
<Spinner animation="grow" variant="danger" />
<Spinner animation="grow" variant="warning" />
</div> : data.infodata.length > 0 ? 
<div className='row'>
    {
data.infodata.map(opt=>{
            var img = opt.frontcover==='' ? process.env.PUBLIC_URL + '/images/'+props.noimg+'.png' : usercxt.baseurl+'backend/_authors/book/'+opt.frontcover;
            return <div className='col-md-3 mb-3 text-center' key={opt.id}>
                <a href={'./'+((props.noimg==='paper') ? 'paper' : 'publication')+'/'+opt.id+'-'+opt.title.toLowerCase().replaceAll(' ', '-')} style={{display:'block'}} >
            <Stack image={img} wid='100' hei='130' radius='10px' />
            </a>
            <div className='text-white mt-2'>{opt.title}</div>
            <div className='text-white'>{usercxt.getcurrency(opt.cur)+decode(opt.price)}</div>
            </div>
        }) 
    } </div>
: <div className='alert alert-info'>No data found.</div>
)

}

export default Homeloda;