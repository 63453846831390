const Paging = (props) => {
var page=0, ceilnum=Math.ceil(props.ttl/12), floornum=Math.floor(props.ttl/12), pages= Array.apply(null, new Array(ceilnum));

	return(
        <div className="d-flex justify-content-center mt-3">
<nav aria-label="Page navigation example text-center">
  <ul className="pagination">
    <li className={'page-item'+((props.index === 0) ? ' disabled':'')}>
      <button onClick={()=>props.onclick(props.index - 12)} className="page-link" aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
      </button>
    </li>
    { 
        pages.map(opt=>{
             page++;
             var index = page===1 ? 0 : props.index + 12, st='';
            if(props.index===0){
                st= page===1 ? ' active' : '';
             }else if(props.index===((page-1) * 12)){
               st=' active';
             }
            return <li key={page} className={'page-item'+st}><button onClick={()=>props.onclick(index)} className="page-link">{page}</button></li>
        })
    }
    <li className={'page-item'+((props.index >= (floornum * 12)) ? ' disabled':'')}>
      <button onClick={()=>props.onclick(props.index + 12)} className="page-link" aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
      </button>
    </li>
  </ul>
</nav></div>
	)

}

export default Paging;