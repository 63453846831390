const Stack = (props) => {
    var rand = Math.floor(Math.random()*16777215).toString(16);
    var rand2 = Math.floor(Math.random()*16777215).toString(16);

	return(
<div style={{position: 'relative',height: props.hei+'px'}}>
<div style={{position: 'absolute', paddingBottom: '50px', paddingRight: '40px', width: props.wid+'px', height: props.hei+'px', backgroundColor: '#'+rand, borderRadius: props.radius, boxShadow: '1px 1px 1px 1px rgba(0, 0, 87, 0.1)'}} >
    <div style={{width: (props.wid * 0.5)+'px', height: (props.hei * 0.5)+'px', backgroundColor: '#'+rand2, borderRadius: props.radius+' 0px 0px 0px'}} ></div>
</div>
<div style={{position: 'absolute', padding:'8px'}} >
    <div style={{padding:'8px', width: props.wid+'px', height: props.hei+'px', border: '1px solid #'+rand2, backgroundColor:'#'+rand, backgroundImage: 'url('+props.image+')', backgroundSize: 'cover', borderRadius: props.radius, boxShadow: '1px 1px 1px 1px rgba(0, 0, 87, 0.1)' }} >
    </div>
</div>
</div>
	)

}

export default Stack;