
import React from 'react';
const Dashboard = ({userdata})=>{

  return (
    <>
    <h1 className="h2">Dashboard and my name is {userdata.firstname}</h1>
    </>
  );
}
export default Dashboard;