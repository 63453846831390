import { useState } from "react";
import {decode} from 'html-entities';

const Textbox = (props) => {
	const [input, setInput] = useState(props.value);
    const setVal = (e)=>{
		props.setvalue(e);
		setInput(e);
	}
	var dataset= input === '' ? decode(props.value) : input;

	return(
        <div>
        <input accept={props.type==='file' ? 'image/*' : ''} id={ props.name } type={ props.type } onChange={(e)=>setVal(e.target.value)} className='form-control' value={dataset} name={ props.name } style={props.style} required={props.name!=='author'} />
		<div className='invalid-feedback'>
        { props.label } is invalid
		</div>
		</div>
	)

}

export default Textbox;