import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'datatables.net-dt';
import {decode} from 'html-entities';
import parser from 'html-react-parser';
import { faAdd, faDeleteLeft, faPen } from '@fortawesome/free-solid-svg-icons';
import Authbutton from '../Authbutton';
import Selectmenu from '../Selectmenu';
import Questionrow from './Questionrow';

var afr=[1];
const Question = ({userdata, param, modalShow, modalClose, modalContent})=>{
  const navigate = useNavigate();
   const[data, setData] = useState({
    'paper':[],
    'question':[],
    'searchkey':'',
    'searchindex':-1,
    'loaded':false,
    'import':false,
    'i':0,
  });

  //const[images, setImages] = useState([]);
  // images.push('myimg.jpg');
  // setImages([...images]);

  useEffect( ()=> {
    if(data.i===0 && userdata.baseurl){
      modalShow();
            fetch(userdata.baseurl+'author/question/fetchonlanding/'+param+'/'+userdata.id)
    .then((res)=>res.json())
            .then(response => {
              if(response==='login'){
              navigate('/');
             }else{
              setData(pstate =>{ 
                return {...pstate,
                  paper:response.p.length===0 ? null : response.p,
                  question:response.q,
                  loaded:true,
                  i:1
                }});
             }
            });
      modalClose();
    }
  }, [data.i]);

  useEffect( () =>{
    if(data.loaded){
      setTimeout(()=>
    new DataTable('#dataTable', {
      responsive: true,
      pageLength:10,
      searching: true, 
      retrieve:true,
  }), 2000); 
}
  }, [data.loaded]);

  const editData = (id) => {
    var findindex = data.question.findIndex((value)=>value.id===id);
  setData(pstate =>{ return {...pstate, searchkey:id, searchindex:findindex}});
  }

  const deleteData = (e) => {
    if(window.confirm('Do you want to delete this?')){
      modalShow();
      fetch(userdata.baseurl+'author/question/delete/'+param+'/'+e)
      .then((res)=>res.json())
              .then(response => {
                modalClose();
                  setData(pstate =>{ return {...pstate, question:response}});
              });
    }
  }

      const handleSubmit = (event) => {
        const seg = data.import===true ? 'import' : 'add';
        event.preventDefault();
        event.stopPropagation();
        event.currentTarget.classList.add('was-validated');
        if (event.currentTarget.checkValidity()) {
          event.currentTarget.classList.remove('was-validated');
          let data = new FormData(event.currentTarget);
          event.currentTarget.reset();
          modalShow();
        fetch(userdata.baseurl+'author/question/'+seg, {method:'POST', body: data})
		.then((res)=>res.json())
            .then(response => { 
              if(response==='login'){
                modalClose();
               navigate('/');
              }else if(response.indexOf('Error') > -1){
                modalContent(response);
              }else{
                modalClose();
              setData(pstate =>{ return {...pstate, question:response, searchindex:-1, searchkey:''}});
              }
                });
      }
        }

 const onaddformrow= (e)=>{
     e.preventDefault(); 
     afr.push(afr.length+1);
     setData(pstate =>{ return {...pstate, searchindex:-1, searchkey:'',import:false}});
 }

 const loadagain = (e)=>{
  afr=[1];
  setData(pstate =>{ return {...pstate, i:0,question:[],loaded:false,searchkey:'',searchindex:-1,import:false,}});
  navigate('/question/'+e);
 }

 const handleReset = (event) => {
  event.preventDefault();
  var currentTarget = document.querySelector('#form');
    currentTarget.classList.remove('was-validated');
    currentTarget.reset();
    setData(pstate =>{ return {...pstate, searchindex:-1, searchkey:''}});
  }

  const uploadMultiple = (event) => {
    event.preventDefault();
        event.stopPropagation();
        event.currentTarget.classList.add('was-validated');
        if (event.currentTarget.checkValidity()) {
          event.currentTarget.classList.remove('was-validated');
          let data = new FormData(event.currentTarget);
          event.currentTarget.reset();
          modalShow();
        fetch(userdata.baseurl+'author/question/uploadmultiple', {method:'POST', body: data})
		.then((res)=>res.json())
            .then(response => { 
              if(response==='login'){
              modalClose();
               navigate('/');
              }else{
                modalContent(response);
              }
    })
  }
}

const download = (e) => {
  e.preventDefault();
  if(window.confirm('Do you want to download this?')){
    window.open(userdata.baseurl+'author/question/download/questions/xlsx', '_blank');
  }
}

  return (
    data.paper.length > 0 &&
    <>
    <div className="row">
    <div className="col-lg-12">
    <div className="card shadow-lg">
    <div className="card-body p-5">
      <h1 className="fs-4 card-title fw-bold mb-4">Add new question</h1>
							<form id='form' onSubmit={handleSubmit} encType='multipart/form-data' noValidate={true}>
              <input name="uid" value={userdata.id} type="hidden" />
            <input name="rid" value={data.searchindex < 0  ? '' : data.question[data.searchindex].id} type="hidden" />
                <div className='row'>
                  <div className='col-sm-3 mb-3'>
              <div className="input-group">
                <Selectmenu 
					name='paper'
          style={{borderRadius: '0.375rem 0 0 0.375rem', maxWidth: '180px'}}
					options={data.paper}
          value={param}
          setvalue={(e)=>loadagain(e)}
					label='Paper'
				/>
              <button onClick={()=>navigate('/paper')} className="mybtn input-group-text">
              <FontAwesomeIcon icon={faAdd} />
                </button>

</div>
                    </div>
                    <div className='col-sm-3 mb-3'>
                    <button onClick={(e)=>{e.preventDefault(); afr=[1]; setData(pstate =>{ return {...pstate, searchkey:'',import:!data.import}});}} className='btn btn-link text-muted'>{data.import ? 'No excel import' : 'Import excel file'}</button>
                    </div>
                    <div className='col-sm-3 mb-3'>
                    {
                    data.import ? 
                    <button onClick={(e)=>download(e)} className="btn btn-info" >Download Format</button>
                    : <button onClick={(e)=>onaddformrow(e)} className="btn btn-info" >Add Form Row</button>
                    }
                    </div>
                    {
                    data.import &&
                    <div className='col-sm-3 mb-3'>
<input type="file" name="import" accept=".csv, .xls, .xlsx" className="form-control" />
</div>
}
                    </div>
{
  afr.map(opt=>{
     return  <Questionrow id={opt} key={opt} import={data.import}
                      qt={data.searchindex < 0  ? '' : data.question[data.searchindex].qt}
                      ot={data.searchindex < 0  ? '' : data.question[data.searchindex].ot}
                      instruction={data.searchindex < 0  ? '' : data.question[data.searchindex].instruction}
                      question={data.searchindex < 0  ? '' : data.question[data.searchindex].question}
                      opta={data.searchindex < 0  ? '' : data.question[data.searchindex].opta}
                      optb={data.searchindex < 0  ? '' : data.question[data.searchindex].optb}
                      optc={data.searchindex < 0  ? '' : data.question[data.searchindex].optc}
                      optd={data.searchindex < 0  ? '' : data.question[data.searchindex].optd}
                      opte={data.searchindex < 0  ? '' : data.question[data.searchindex].opte}
                      answer={data.searchindex < 0  ? '' : data.question[data.searchindex].answer}
                      des={data.searchindex < 0  ? '' : data.question[data.searchindex].des}
              />
    })
}

								<div className="d-flex align-items-center">
                <button onClick={handleReset} className="btn btn-link text-dark">Reset form</button>
									<Authbutton name='Submit' />
								</div>
            </form>  
            </div>
          </div>

          <div className="card shadow-lg my-5">
    <div className="card-body p-5">
      <h3 className="fs-4 card-title fw-bold mb-4">Upload images for the imported excel data</h3>
							<form onSubmit={uploadMultiple} encType='multipart/form-data' noValidate={true}>
              <input name="uid" value={userdata.id} type="hidden" /> 
              <input type='file' accept='image/*' className='form-control mb-2' style={{width:'50%'}} name='files[]' /> 
              <input type='file' accept='image/*' className='form-control mb-2' style={{width:'50%'}} name='files[]' /> 
              <input type='file' accept='image/*' className='form-control mb-2' style={{width:'50%'}} name='files[]' /> 
              <input type='file' accept='image/*' className='form-control mb-2' style={{width:'50%'}} name='files[]' /> 
              <input type='file' accept='image/*' className='form-control mb-2' style={{width:'50%'}} name='files[]' />  
                                <button type="submit" className="btn btn-info pull-right my-4">Upload</button>
                            </form></div></div>
          </div>

          <div className="col-lg-12">
          <div className="card shadow-lg">
    <div className="card-body p-5">
          <div className="table-responsive">
          <table id="dataTable" style={{width:'100%'}} className="table table-striped table-bordered">
              <thead>
              <tr>
                                        <th scope="col">Category</th>
                                        <th scope="col">Subject</th>
                                        <th scope="col">Question</th>
                                        <th scope="col">Answer</th>
                                        <th scope="col">Action</th>
                                    </tr>
              </thead>
              <tbody>
                {
              data.question.length > 0 &&
              data.question.map(opt=>{
            return <tr key={opt.id}>
              <td className={opt.id === data.searchkey ? 'text-bg-primary' : ''} > {opt.cat} </td>
              <td className={opt.id === data.searchkey ? 'text-bg-primary' : ''} > {opt.subj} </td>
              <td className={opt.id === data.searchkey ? 'text-bg-primary' : ''} > {parser(decode(opt.question))} </td>
              <td className={opt.id === data.searchkey ? 'text-bg-primary' : ''} > {decode(opt.answer)} </td>
                                            <td style={{width: '100px'}} className={opt.id === data.searchkey ? 'text-bg-primary' : ''} >
                                            <button onClick={()=>editData(opt.id)} className="btnlite" title="Edit">
                                            <FontAwesomeIcon icon={faPen} />
                                                    </button>
                                                    <button onClick={()=>deleteData(opt.id)} className="btnlite" title="Delete">
                                                    <FontAwesomeIcon icon={faDeleteLeft} />
                                                    </button>
                                            </td>
            </tr>
        }) 
      }
                </tbody>
                </table>
                </div>
                </div>
                </div>
    </div>
  </div>
    </>
  );
}
export default Question;