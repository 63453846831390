
import { useState } from "react";
import Texteditor from "../Texteditor";
import Selectmenu from '../Selectmenu';
import Textbox from "../Textbox";

const Questionrow = (props) => {
	const [data, setData] = useState({'qt':'at', 'ot':'wo'});
        
	return(
        <>
        <h4>Form row {props.id}</h4>
        <div className='row mb-3'>
        <div className='col-sm-4 mb-3'>
    <Selectmenu 
        name='qt[]'
        options={[{value:'at', label:'All text'}, {value:'ai', label:'All image'}, {value:'qt', label:'Only question is a text'}, {value:'qi', label:'Only question is an image'}]}
value={props.qt==='' ? data.qt : props.qt}
style={{height:'auto'}}
setvalue={(e)=>setData(pstate =>{ return {...pstate, qt:e}})}
        label='Question type'
    />
        </div>

        <div className='col-sm-4 mb-3'>
                        <Selectmenu 
        name='ot[]'
        options={[{value:'wo', label:'With Options'}, {value:'no', label:'No Options'}]}
value={props.ot==='' ? data.ot : props.ot}
style={{height:'auto'}}
setvalue={(e)=>setData(pstate =>{ return {...pstate, ot:e}})}
        label='Option type'
    />
        </div>
        {
    !props.import &&
     <div className='col-sm-4 mb-3'>
            {
                data.ot==='wo' ?
                <Selectmenu
                setvalue={(e)=>null} 
                name='answer[]' 
                label='Answer' 
                style={{height:'auto'}}
                options={[{value:'A', label:'A'}, {value:'B', label:'B'}, {value:'C', label:'C'}, {value:'D', label:'D'}, {value:'E', label:'E'}]}
value={props.answer} />
        : <Textbox 
        value={props.answer} 
        type='text'
	style={{height:'auto'}}
        setvalue={(e)=>null}
        name='answer[]' 
        label='Answer' />
            }
        </div>
}

<div style={{width:props.import ? '50%':'33.333%'}} className='col-sm-4  mb-3'>
        <Texteditor value={props.instruction} name='instru[]' label='Instruction' />
        </div>

{
    !props.import &&
    <>
        <div className='col-sm-4 mb-3'>
            {
                data.qt==='at' || data.qt==='qt' ?
        <Texteditor value={props.question} name='ques[]' label='Question' />
        : 
        <>
        <label className="mb-2 text-muted" htmlFor="ques">Question</label>
        <Textbox 
        type='file' 
        value={props.question} 
	style={{height:'auto'}}
        setvalue={(e)=>null}
        name='ques[]' 
        label='Question' />
        </>
            }
        </div>
{
data.ot==='wo' &&
<>  
        <div className='col-sm-4 mb-3'>
        {
                data.qt==='at' || data.qt==='qi' ?
        <Texteditor value={props.opta} name='opta[]' label='Option A' />
        : 
        <>
        <label className="mb-2 text-muted" htmlFor="opta">Option A</label>
        <Textbox 
        type='file' 
	style={{height:'auto'}}
        setvalue={(e)=>null} 
        value={props.opta} 
        name='opta[]' 
        label='Option A' 
        />
        </>
            }
        </div>

        <div className='col-sm-4 mb-3'>
        {
                data.qt==='at' || data.qt==='qi' ?
        <Texteditor value={props.optb} name='optb[]' label='Option B' />
        :
        <>
        <label className="mb-2 text-muted" htmlFor="optb">Option B</label> 
        <Textbox 
        type='file' 
	style={{height:'auto'}}
        setvalue={(e)=>null} 
        value={props.optb} 
        name='optb[]' 
        label='Option B' 
        />
        </>
            }
        </div>

        <div className='col-sm-4 mb-3'>
        {
                data.qt==='at' || data.qt==='qi' ?
        <Texteditor value={props.optc} name='optc[]' label='Option C' />
        : 
        <>
        <label className="mb-2 text-muted" htmlFor="optc">Option C</label>
        <Textbox 
        type='file' 
	style={{height:'auto'}}
        setvalue={(e)=>null} 
        value={props.optc} 
        name='optc[]' 
        label='Option C' 
        />
        </>
            }
        </div>

        <div className='col-sm-4 mb-3'>
        {
                data.qt==='at' || data.qt==='qi' ?
        <Texteditor value={props.optd} name='optd[]' label='Option D' />
        : 
        <>
        <label className="mb-2 text-muted" htmlFor="optd">Option D</label>
        <Textbox 
        type='file' 
	style={{height:'auto'}}
        setvalue={(e)=>null}
        value={props.optd} 
        name='optd[]' 
        label='Option D' />
        </>
            }
        </div>

        <div className='col-sm-4 mb-3'>
        {
                data.qt==='at' || data.qt==='qi' ?
        <Texteditor value={props.opte} name='opte[]' label='Option E' />
        : 
        <>
        <label className="mb-2 text-muted" htmlFor="opte">Option E</label>
        <Textbox 
        type='file' 
	style={{height:'auto'}}
        setvalue={(e)=>null} 
        value={props.opte} 
        name='opte[]' 
        label='Option E' />
        </>
            }
        </div>
        </>
}
        <div className='col-sm-4 mb-3'>
        <Texteditor value={props.des} name='des[]' label='Hint' />
        </div>
        </>
}
        </div>
        </>
	)

}
export default Questionrow;