
import Textbox from "../Textbox";

const Chapterow = (props) => {
	return(
        <div className='mb-3'>
                <label className="mb-2 text-muted" htmlFor="title">Title</label>
        <Textbox 
        value={props.title} 
        name='title[]' 
					
type='text'
					style={{height:'auto'}}
                                        setvalue={(e)=>null}
        label='Title' />
        </div>
	)

}
export default Chapterow;