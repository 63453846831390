import { useState } from "react";
import Textbox from "../Textbox";
import Texteditor from "../Texteditor";

const Paragraphrow = (props) => {
    const [data, setData] = useState(props.di);

	return(<>
        <h4>Form row {props.id}</h4>
        <div className='mb-3'>
                <label className="mb-2 text-muted" htmlFor="hint">Title</label>
        <Textbox 
        value={props.title} 
        type='text'
					style={{height:'auto'}}
                                        setvalue={(e)=>null}
        name='hint[]' 
        label='Title' />
        </div>

        <div className='mb-3'>
        <Texteditor value={props.txt} name='title[]' label='Body text' />
        </div>

        <div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="file0">Add Image</label>
        <input 
name='file[]'
type='file'
accept='image/*'
className='form-control'
/>
</div>

        <div className="mb-3">
                                    <label className="mb-2 text-muted" htmlFor="vis">Display image</label>
                                <div style={{borderRadius: '0'}} className="form-check-inline input-group-text">
                          <label>
                                    <input onChange={(e)=>setData(e.target.value)} checked={data==='bb'} className="form-check-input me-2" value="bb" name={"di"+(props.id-1)} type="radio" />
                                    Before body text
                                  </label>
                                  <label className="inline ms-4">
                                    <input onChange={(e)=>setData(e.target.value)} checked={data ==='ab'} className="form-check-input me-2" value="ab" name={"di"+(props.id-1)} type="radio" />
                                    After body text
                                  </label>                                  
                                </div> 
                                    </div>
        </>
	)

}
export default Paragraphrow;