import Textbox from '../Textbox';
import Authbutton from '../Authbutton';
import { Link } from 'react-router-dom';

const Login = (props)=>{
  return (
    <div className="card">
    <div className="card-body p-5">
      <h1 className="fs-4 card-title fw-bold mb-4">Author's Login</h1>
							<form onSubmit={props.submit} noValidate={true}>
              <div className="mb-3">        
		<label className='mb-2 text-muted' htmlFor='email' >Email Address</label>
              <Textbox 
					name='email'
					type='email'
					label='Email Address'
					value=''
					style={{height:'auto'}}
					setvalue={(e)=>null}
				/>
        </div>

        <div className="mb-3">        
        <div className="mb-2 w-100">
										<label className="text-muted" htmlFor="password">Password</label>
										<Link state="/forgotpassword" className="float-end" to="/forgotpassword">Forgot Password?</Link>
									</div>
        <Textbox 
					name='password'
					type='password'
					label='Password'
					value=''
					style={{height:'auto'}}
					setvalue={(e)=>null}
				/>
        </div>

								<div className="d-flex align-items-center">
									<div className="form-check">
										<input type="checkbox" name="remember" id="remember" className="form-check-input" />
										<label htmlFor="remember" className="form-check-label">Remember Me</label>
									</div>
									<Authbutton name='Login' />
								</div>
            </form>  
            </div>
            <div className="card-footer py-3 border-0">
            <div className="text-center">
              Don't have an account? <Link className="text-dark" to="/register">Create One</Link>
            </div>
          </div>
          </div>
  );
}

export default Login;
