import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

const MyModal = (props) => {
  return props.body ==='' ? ( 
    <Modal id='modalcontent' centered={true} backdrop='static' onHide={props.onhide} show={props.show} >
<Modal.Dialog>
<Spinner animation="grow" variant="success" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="warning" />
</Modal.Dialog>
    </Modal>
  ):(
  <Modal size='sm' fullscreen='sm-down' backdrop='static' onHide={props.onhide} show={props.show} >
<Modal.Dialog>
  <Modal.Header closeButton={props.head==='Please wait...' ? false:true}>
<Modal.Title>{props.head}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  {props.body}
  </Modal.Body>
</Modal.Dialog>
</Modal>
  );
}

export default MyModal;