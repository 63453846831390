
import React, { useContext } from 'react';
import {UserContext} from '../contexts/UserContext';

function Header() {
  const usercxt = useContext(UserContext);

return(<div className="cover-container d-flex w-100 p-3 mx-auto flex-column">
      <header className="mb-auto">
        <div>
          <h3 className="float-md-start mb-0"><img src={process.env.PUBLIC_URL + '/images/logo.svg'} width="100" alt="MOLINA EDUCATION" /></h3>
          <nav className="nav nav-masthead justify-content-center float-md-end">
            <a className="nav-link fw-bold py-1 px-0 active" aria-current="page" href={usercxt.href} >Home</a>
            <a className="nav-link fw-bold py-1 px-0" href={usercxt.href+"login"} >Login</a>
            <a className="nav-link fw-bold py-1 px-0" href={usercxt.href+"register"} >Register</a>
          </nav>
        </div>
      </header>
      </div>
      )

    }
    
    export default Header;