import Textbox from '../Textbox';
import Authbutton from '../Authbutton';
import Selectmenu from '../Selectmenu';
import { Link } from 'react-router-dom';

const Register= (props) =>{
  return (
    <div className="card">
						<div className="card-body p-5">
							<h1 className="fs-4 card-title fw-bold mb-4">Author's Registration</h1>
							<form onSubmit={props.submit} noValidate={true} >
								<div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="firstname">First name</label>
									<Textbox 
					name='firstname'
					type='text'
					label='First name'
					value=''
					style={{height:'auto'}}
					setvalue={(e)=>null}
				/>
								</div>

								<div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="lastname">Last name</label>
									<Textbox 
					name='lastname'
					type='text'
					value=''
					style={{height:'auto'}}
					label='Last name'
					setvalue={(e)=>null}
				/>
								</div>

								<div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="email">Email Address</label>
									<Textbox 
					name='email'
					type='email'
					value=''
					style={{height:'auto'}}
					label='Email Address'
					setvalue={(e)=>null}
				/>
								</div>

								<div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="password">Password</label>
									<Textbox 
					name='password'
					type='password'
					label='Password'
					value=''
					style={{height:'auto'}}
					setvalue={(e)=>null}
				/>
								</div>

								<div className="mb-3">
									<label className="mb-2 text-muted" htmlFor="gender">Gender</label>
									<Selectmenu 
					name='gender'
					options={[{value:'M', label:'Male'}, {value:'F', label:'Female'}]}
					value=''
					style={{height:'auto'}}
					setvalue={(e)=>null}
					label='Gender'
				/>
									</div>

								<p className="form-text text-muted mb-3">
									By registering you agree with our terms and condition.
								</p>

								<div className="align-items-center d-flex">
                                <Authbutton name='Register' />
								</div>
							</form>
						</div>
						<div className="card-footer py-3 border-0">
							<div className="text-center">
								Already have an account? <Link className="text-dark" to="/login">Login</Link>
							</div>
						</div>
					</div>
  );
}

export default Register;
