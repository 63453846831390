 import React, { createContext, useState } from 'react';
 import {decode} from 'html-entities';

export const UserContext = createContext();

const UserContextProvider = (props) => {

	const [user, setUser] = useState(null);
	const changeUserStatus = (data) => setUser(data);
	/* const baseurl='https://localhost/molina/';
	const href='http://localhost:3000/'; */
	const baseurl='https://molina.gaamentorship.com/server/';
	const href='https://molina.gaamentorship.com/';
	const currency = [
		{value:'NGN', label:"&#8358;"},
		{value:'USD', label:'$'},
		{value:'GBP', label:'£'},
		{value:'EUR', label:'€'}
		];

	const getcurrency = (data)=>{
		if(data==='NGN'){
			return decode("&#8358;");
		}else if(data==='USD'){
			return '$';
		}else if(data==='GBP'){
			return '£';
		}else{
			return '€';
		}
	}

	return(
		<UserContext.Provider value={{ user, baseurl,href, currency, getcurrency, changeUserStatus, }} >
			{ props.children }
		</UserContext.Provider>
	);
}

export default UserContextProvider; 