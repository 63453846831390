import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import {decode} from 'html-entities';
import Stack  from './widgets/Stack';
import parser from 'html-react-parser';
import Header from './widgets/Header';
import {UserContext} from './contexts/UserContext';

function Detail() {
  const[data, setData] = useState({
    'infodata':{},
    'infotype':[],
    'index':0,
  });

	const usercxt = useContext(UserContext);
  var loc=useLocation(), a=loc.pathname.split('/'), b=a[2].split('-'), noimg= a[1]==='paper' ? 'paper' : 'book', lnk = usercxt.baseurl+'author/'+noimg+'/detail/'+b[0], i=-1;
  var wid = Math.floor(window.innerWidth * 0.25 * 0.92) - 20;

  const handleSelect = (selectedIndex) => setData(pstate =>{ 
      return {...pstate,
        index:selectedIndex
      }});

  useEffect( ()=> {
    fetch(lnk)
.then((res)=>res.json())
    .then(response => {
      setData(pstate =>{ 
        return {...pstate,
            infodata:response.d,
            infotype:response.c,
        }});
    });
}, [lnk]); 

  return (
    <div className='h-100 text-bg-dark'>
      <Header/>
      <div className="d-md-flex flex-md-equal w-100 my-md-3 ps-md-3" style={{backgroundColor:'#fff'}} >
        <div className="me-md-3 pt-3 px-3 px-md-5 overflow-hidden" >
        <div className="text-center p-2" style={{backgroundColor:'#2F4F4F', fontSize:'5em', height:'600px', color:'#fff'}} >
                <img alt={data.infodata.title} style={{width:'100%', display:'block', height:'100%'}} src={usercxt.baseurl+'backend/_authors/book/'+data.infodata.frontcover} />
           </div>
        </div>
        <div className="me-md-3 pt-3 px-3 px-md-5 overflow-hidden">
          <div className='bd-gray-400 p-3'>
            {
              a[1]==='book' &&
          <div className='bd-gray-400 py-1'><h5 className='p-0 ms-3'>Type: {data.infodata.pubtype}</h5></div>
            }
          <div className='bd-gray-500 py-1 my-3'><h5 className='p-0 ms-3'>Category: {data.infodata.category}</h5></div>
          <div className='bd-gray-500 py-1'><h5 className='p-0 ms-3'>Title: {data.infodata.title}</h5></div>
          </div>
          <div className='bd-cyan-700 p-3'>
          <div className='bd-cyan-700 py-1'><h5 className='p-0 ms-3'>Price: {
                  usercxt.getcurrency(data.infodata.cur)+decode(data.infodata.price)
                }</h5></div>
                {
              a[1]==='book' &&
          <div className='bd-cyan-800 py-1 my-3'><h5 className='p-0 ms-3'>Author: {data.infodata.author}</h5></div>
                }
          <div className='bd-cyan-800 py-1'><h5 className='p-0 ms-3'>Owner: <img width='30px' height='30px' style={{borderRadius:'50%', marginLeft:'10px'}} alt="..." src={usercxt.baseurl+'/backend/_users/'+data.infodata.avatar} /> {data.infodata.firstname}</h5></div>
          </div>
          <div className='bd-orange-600 p-3'>
          <h4 className='p-0 ms-3 text-center'>Preview</h4>
          <div className='bd-orange-700 py-1'><h5 style={{textAlign:'justify'}} className='px-3'>{parser(decode(data.infodata.preview))}</h5></div>
          </div>
          </div>
        </div>
        <div className='mt-5 mb-2' id="sliderTitle">
      <h2 style={{fontSize:"22px", textTransform: 'uppercase'}} >OTHERS IN {a[1]==='book' ? data.infodata.pubtype : data.infodata.category} </h2>
    </div>
    
    {
        data.infotype.length > 0 &&
        <div className='my-3' id="sliderContainer">
    <Carousel indicators={false} activeIndex={data.index} onSelect={handleSelect} >
        {
          Array.apply(null, new Array(Math.ceil(data.infotype.length/4))).map(x=>{
            i++;
            var ar = data.infotype.slice(i, (i+4));
            return (
              <Carousel.Item key={i} >
                <div className='row' >
                {
                  ar.map(opt=>{
                    var img = opt.frontcover==='' ? process.env.PUBLIC_URL + '/images/'+noimg+'.png' : usercxt.baseurl+'backend/_authors/'+noimg+'/'+opt.frontcover;
                    return (
                    <div key={opt.id} className="col-md-3 text-center mb-3">
                      <a href={usercxt.href+((a[1]==='paper') ? 'paper' : 'publication')+'/'+opt.id+'-'+opt.title.toLowerCase().replace(' ', '-')} style={{display:'block', width:'100%'}} >
                        <Stack image={img} wid={wid} hei='350' radius='10px' />
                        </a>
                        <div className='text-white mt-2'>{opt.title}</div>
                        <div className='text-white'>{usercxt.getcurrency(opt.cur)+decode(opt.price)}</div>
                      </div>)
                  })
                }
                </div>
              </Carousel.Item>
            )
          }
          )
        }
        </Carousel>
      </div>
}
      </div>
      )
    }
    export default Detail;